<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="12">
        <h3 class="text-decoration-underline">Environemnt Settings</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" md="4">
          <v-checkbox
            id="devSettingCheckboxIsTestEnvironment"
            class="dev-setting-checkbox-is-test-environment"
            v-model="editItem.isTestEnvironment"
            label="Use this environment as Test"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-card-actions>
          <v-btn
            id="devSettingSaveBtn"
            class="dev-setting-save-btn"
            depressed
            color="primary"
            @click="update"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';

const configScope = 'devConfig';

export default {
  computed: {
    ...mapState({
      editItem: (state) => state.SystemConfig.devSettingItem || {},
    }),
  },
  created() {
    this.loadConfig({ requiredScope: configScope });
  },
  methods: {
    ...mapActions('SystemConfig', ['loadConfig', 'updateConfig']),
    update() {
      if (window.confirm('Are you sure want to update this configuration?')) {
        const payload = { calledScope: configScope, item: this.editItem };
        this.updateConfig(payload);
      }
    },
  },
};
</script>
